(function () {
    'use strict';

    // VERY ugly but it is to avoid lint errors
    window.IS_DEV = 'false' === 'true';

    var doc = window.document;
    // Parse out the query string parameters
    var params = doc.location.search.substr(1).split('&').reduce(function (all, pair) {
        var parts = pair.split('=').map(decodeURIComponent);
        if (parts[0])
            all[parts[0]] = parts[1];
        return all;
    }, {});

    var lang = params.l;
    // We set the `lang` property on the root element (html) on the front-end
    // since we use a "dummy" backend, which cannot do this for us.
    // Our require-js init code looks at this value to load proper translations
    if (lang && lang !== 'en')
        doc.documentElement.lang = lang;

    // Ideally we'd look up the language code in locale/mapping.json file but
    // since the list of RTL languages is quite short and not changing over
    // time, we simply hard-code them here as a look-up table
    var rtlLanguages = {
        'ar': 1,
        'ar_SA': 1,
        'he': 1,
        'he_IL': 1,
        'nqo': 1,
        'fa': 1,
        'fa_IR': 1,
        'ur': 1,
        'ur_PK': 1,
    };

    var loadCss = function (path) {
        var style = doc.createElement('link');
        style.rel = 'stylesheet';
        style.href = '//c.disquscdn.com/next/current/home/css/' + path + '.css';
        doc.head.appendChild(style);
    };

    var mainCSSPath = 'main';

    if (rtlLanguages[lang]) {
        doc.documentElement.dir = 'rtl';
        mainCSSPath += '_rtl';
    }

    loadCss(mainCSSPath);
    // TODO: move this into dashboard-imports.css maybe?
    // TODO: make this RTL compatible too may be?
    loadCss('hovercards');

    // Using addEventListener to detect IE9 and above
    // Cannot use conditional comments because they don't work
    // in IE10 standards mode:
    // http://msdn.microsoft.com/en-us/library/ms537512.aspx
    var body = doc.body;
    var unsupportedBrowser = doc.getElementById('unsupportedBrowser');
    if (!doc.addEventListener) {
        body.innerHTML = '<p>' + unsupportedBrowser.innerHTML + '</p>';
        return;
    }

    var isSidebar = function () {
        try {
            // If window.self !== window.top then we're iFramed
            // and this is the home sidebar
            return window.self !== window.top;
        } catch (err) {
            // If we got an error, it was a same origin policy error,
            // which means we _are_ in an iFrame.
            return true;
        }
    };

    if (isSidebar())
        body.setAttribute('class', body.className + ' home-drawer');

    // --- requirejs config init + fallback support
    // Include module name here so we can require it after definition (same file)
    define('initializer', [  // eslint-disable-line requirejs/no-named-define
        'require',
        'exports',

        // 'require' will be referred to as localRequire here to avoid shadowing
        // the global require variable. This is necessary to let us update the
        // global config via require.config.
    ], function (
        localRequire,
        exports
    ) {
        exports.ready = false;

        exports.setReady = function () {
            localRequire(['common/bus'], function (Bus) {
                Bus.sendHostMessage('ready');
                exports.ready = true;
            });
        };

        /**
         * This method loads the resources necessary for the application.
         *
         * When the application module is loaded, this function will call the
         * application module's init function. It will call this function even if
         * there is an error loading the translations file.
         */
        exports.init = function () {
            var language = doc.documentElement.lang;
            var requireConfig = {
                baseUrl: '//c.disquscdn.com/next/current/home/js',
                paths: { 'translations': '../lang/' + language },
            };

            if (!window.IS_DEV) {
                requireConfig.bundles = {
                    'home/main': ['moment'],  // required for moment translations
                };
            }

            if (!language || language === 'en')
                define('translations', {});  // eslint-disable-line requirejs/no-named-define

            var CDN_ROOT = '//c.disquscdn.com/next/current/home';

            function depsReady() {
                // NOTE: These modules should all be in the app bundle,
                //       so require won't trigger another fetch
                localRequire([
                    'core/analytics/identity',

                    'home/main',
                    'loglevel',
                    'raven',
                ], function (
                    identity,

                    app,
                    logger,
                    Raven
                ) {
                    var logLevel = window.IS_DEV ? 'DEBUG' : 'SILENT';
                    logger.setDefaultLevel(logLevel);

                    // Must instantiate identity cookie managers upon app initialization with the is_private state
                    identity.init({
                        isPrivate: window.home_config ? window.home_config.is_private : false,
                    });

                    // Don't bother initializing Raven in IE9 or below since we don't support those actively and
                    // they also generate garbage error reports most of the time.
                    var isIE9OrBelow = doc.documentMode && doc.documentMode < 10;  // eslint-disable-line no-magic-numbers
                    if (!window.IS_DEV && !isIE9OrBelow) {
                        Raven.config('//1a2772a06861430ebd15caa640a82975@sentry.services.disqus.com/28', {
                            whitelistUrls: [
                                CDN_ROOT,
                                // We need CDN_CURRENT to include errors in this file
                                '//c.disquscdn.com/next/current/home',
                            ],
                            ignoreErrors: [
                                // Ignore unrelated errors from TiApp
                                // See http://sentry.local.disqus.net/disqus/home/group/641903/
                                /\bTiApp\b/,
                            ],
                            release: 'current',
                        }).install();
                    }
                    app.init({
                        CDN_ROOT: CDN_ROOT,
                    });
                });
            }

            function provideModuleFallback(name, fallback) {
                // Replace the module definition with the fallback
                require.undef(name);
                define(name, fallback);  // eslint-disable-line requirejs/no-invalid-define

                // Trigger another load of the module. When RequireJS sees this
                // succeed it will automatically trigger the callbacks depending on
                // this module so we don't have to call `depsReady` manually.
                localRequire([name]);
            }

            var moduleFallbacks = {
                'translations': {},  // Translations files may not exist for a specific language
                'remote/config': {  // Server may fail to generate config
                    lounge: {},
                    discovery: {},
                    experiments: {},
                },
            };

            function depsFailed(error) {
                // This callback is used for any errors associated with module loading.
                // It may be called multiple times, for each module which failed.

                // Log the error
                /* eslint-disable no-console */
                if (typeof console !== 'undefined' && typeof console.log === 'function')
                    console.log(error.toString());
                /* eslint-enable no-console */

                // Some modules are not required for the application to function
                // Provide fallbacks if they failed to load
                var failedModules = error.requireModules || [];
                var failedModule, fallback;
                for (var i = 0; i < failedModules.length; ++i) {
                    failedModule = failedModules[i];
                    fallback = moduleFallbacks[failedModule];

                    if (fallback)
                        provideModuleFallback(failedModule, fallback);
                    // TODO: Add a proper "load failed, refresh?" handler and reporting here as the `else` clause
                }
            }

            require.config(requireConfig);
            localRequire(['home/main', 'translations'], depsReady, depsFailed);
        };
    });
    require(['initializer'], function (i) { i.init(); });

    // Livereload support
    // Will be removed by uglify in production
    /* eslint-disable */
    if ('false' === 'true')
        require(['//media.dev.disqus.org:9090/livereload.js']);
    /* eslint-enable */
}());

